import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const PastQueens = () => {
  return (
    <section className="mb-5">
      <Link to="/mspi/queens">
        <div className="py-16 mx-auto overflow-hidden bg-opacity-25 rounded-lg shadow max-w-7xl bg-swa-4 sm:py-12 sm:px-6 lg:px-8 ">
          <div className="px-4 py-5 sm:p-6">
            <div className="text-center">
              <p className="mt-1 text-4xl font-extrabold text-swa-1 sm:text-5xl sm:tracking-tight lg:text-6xl">
                Past Queens
              </p>
              <p className="max-w-xl mx-auto mt-5 text-xl text-gray-500">
                Complete list of past winners of Miss Singapore International,
                Miss Singapore Supranational and other titles.
              </p>
            </div>
          </div>

          <section className="mx-1 mt-10 text-xl font-light border-black text-swa-2">
            <div className="grid grid-cols-2 my-5 gap-x-1 gap-y-1 sm:grid-cols-3 sm:gap-x-1 lg:grid-cols-4 xl:gap-x-1">
              <div className="w-full ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637206792/mspiPage/oro2ysm4gddaheqabtks.png"
                  alt="past queen"
                  className="rounded-lg"
                  aspectRatio={10 / 9}
                  objectPosition={"top"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637206791/mspiPage/pwmoqmg5kxykx8zflws9.png"
                  alt="past queen"
                  aspectRatio={10 / 9}
                  objectPosition={"top"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group as ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637206791/mspiPage/yvtv8x2aa4pwqczcfa8c.png"
                  alt="past queen"
                  aspectRatio={10 / 9}
                  objectPosition={"top"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637206791/mspiPage/c8rlqoxnzkxeffuybfxo.png"
                  alt="past queen"
                  aspectRatio={10 / 9}
                  objectPosition={"top"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group as ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637206791/mspiPage/pe2geyysqa2t2pvbb878.png"
                  alt="past queen"
                  aspectRatio={10 / 9}
                  objectPosition={"top"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group as ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637206791/mspiPage/xisc4g56puwarhz2bkjb.png"
                  alt="past queen"
                  aspectRatio={10 / 9}
                  objectPosition={"top"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637206791/mspiPage/fcur6m7ffdaaeavywmtf.png"
                  alt="past queen"
                  aspectRatio={10 / 9}
                  objectPosition={"top"}
                />
              </div>
              <div className="block w-full overflow-hidden rounded-lg group ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1637207267/mspiPage/vdruslhuhd4z2vw6c96m.jpg"
                  alt="past queen"
                  // aspectRatio={10 / 7}
                  // objectPosition={"50% 0%"}
                />
              </div>
            </div>
          </section>
        </div>
      </Link>
    </section>
  );
};

export default PastQueens;
