import React from "react";
import { Link } from "gatsby";

const MSPIapp = () => {
  return (
    <section className="mb-5 border border-gray-400">
      <div className="bg-swa-2 ">
        <div className="max-w-2xl px-4 py-16 mx-auto text-center sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            {/* <span className="block">Be a Queen!</span> */}
            <span className="block">Application is now open!</span>
          </h2>
          <p className="mt-4 text-lg leading-6 text-gray-200">
            Audition for Miss Singapore Pageant International 2025 is now open
            for application.
          </p>
          <Link
            to="/forms/mspi"
            className="inline-flex items-center justify-center w-full px-5 py-3 mt-8 text-base font-medium bg-white border border-transparent rounded-md text-swa-2 hover:bg-indigo-50 sm:w-auto"
          >
            Apply!
          </Link>
        </div>
      </div>
    </section>
  );
};

export default MSPIapp;
