import React from "react";
const AnimatedBackground = () => {
  return (
    <div className="absolute w-full ">
      <div className="relative mx-auto h-72 ">
        <div className="absolute w-48 h-48 bg-purple-300 bg-opacity-50 top-16 right-1/3 mix-blend-multiply animate-pulse filter blur"></div>
        <div className="absolute w-56 h-56 bg-pink-300 bg-opacity-50 top-32 right-1/4 mix-blend-multiply animate-pulse filter blur"></div>
        <div className="absolute top-0 bg-opacity-70 right-96 w-72 h-72 bg-swa-4 mix-blend-multiply animate-pulse filter blur"></div>
      </div>
    </div>
  );
};

export default AnimatedBackground;
