import React, { useState } from "react";
import { browserName } from "react-device-detect";

import Layout3 from "../../components/layout/layout3";

import CurrentQueens from "../../components/sections/mspi/CurrentQueens";
import Header from "../../components/sections/mspi/Header";
import Intro from "../../components/sections/mspi/Intro";
import MissAsiaPacific from "../../components/sections/mspi/MissAsiaPacific";
import MissInternational from "../../components/sections/mspi/MissInternational";
import MissSupranational from "../../components/sections/mspi/MissSupranational";
import MissTourism from "../../components/sections/mspi/MissTourism";
import MSPIapp from "../../components/sections/mspi/MSPIapp";
import PastQueens from "../../components/sections/mspi/PastQueens";
import AnimatedBackground from "../../components/sections/mspi/AnimatedBackground";
import AnimatedBackground2 from "../../components/sections/mspi/AnimatedBackground2";
// import CharityDinner from "../../components/sections/mspi/CharityDinner";
// import FooterVideo from "../../components/sections/mspi/FooterVideo";
// import MSPIvideo from "../../images/mspi.mp4";
// import MSPIphoto from "../../images/mspi.jpg";

import SEO from "../../components/seo-mspi";

const MSPI = () => {
  // const [queens, setQueens] = useState();

  // console.log("browser: ", browserName);

  return (
    <>
      {/* --- background pattern --- */}

      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <div id="fb-root"></div>
      <script
        async
        defer
        crossorigin="anonymous"
        src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v12.0"
        nonce="jOwTeTmv"
      ></script>
      <Layout3>
        <SEO
          title="Miss Singapore Pageant International"
          description="Miss Singapore International will represent Singapore on an International platform with 84 contestants around the world. The exchanges and exposure aim to bring forth more women to anchor a role in the society with a charitable approach, grace, integrity and honour."
          image="https://res.cloudinary.com/dkm14ge3g/image/upload/v1638588570/Logo/qiuk4zzjcgmidemdqavw.webp"
          imageAlt="Miss Singapore International Pageant | Annual Charity Gala Dinner"
        />
        {/* {browserName !== "Safari" && <Header />} */}
        
        <CurrentQueens />
        <Intro />
        <MSPIapp />
        <AnimatedBackground />
        <MissInternational />
        <AnimatedBackground2 />
        <MissSupranational />
        <MissAsiaPacific />
        <MissTourism />
        <MSPIapp />
        <PastQueens />

        <div id="fb-root"></div>
        <script
          async
          defer
          crossorigin="anonymous"
          src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v12.0"
          nonce="xpnhPIpC"
        ></script>
        <div
          class="fb-share-button"
          data-href="https://www.singaporewomenassociation.org/mspi/"
          data-layout="button"
          data-size="large"
        ></div>
      </Layout3>
    </>
  );
};

export default MSPI;
