import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const MissTourism = () => {
  return (
    <section className="mb-5">
      <div className="flex flex-col-reverse justify-center max-w-2xl px-4 py-24 mx-auto space-x-10 xl:space-y-0 xl:space-x-5 xl:flex-row sm:px-6 lg:max-w-7xl lg:px-8 ">
        <div className="flex flex-row justify-center mt-5 space-x-3 xl:flex-col xl:space-y-3">
          <div className="w-32 h-48 ml-3 overflow-hidden rounded-lg sm:w-48">
            <StaticImage
              src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1634181800/mspiPage/tbeiofc5pklbsrs45tl7.jpg"
              alt=""
              className="object-cover object-top w-full h-full"
              objectPosition={"top"}
            />
          </div>
          <div className="w-32 h-48 overflow-hidden rounded-lg sm:w-48">
            <StaticImage
              src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1634181801/mspiPage/musbp0wsm7xm7vludfts.jpg"
              alt=""
              className="object-cover object-top w-full h-full"
              objectPosition={"top"}
            />
          </div>
          <div className="w-32 h-48 overflow-hidden rounded-lg sm:w-48">
            <StaticImage
              src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1634181800/mspiPage/q4bfpj6gyuedjjfvfg0y.jpg"
              alt=""
              className="object-cover object-top w-full h-full"
              objectPosition={"top"}
            />
          </div>
        </div>
        <div className="self-center md:max-w-xl">
          <h2 className="text-4xl font-bold tracking-tight text-swa-1 font sm:text-6xl">
            Miss Singapore Tourism Queen of the Year International
          </h2>
          <p className="mt-4 text-xl text-gray-500">
            Miss Tourism International Pageant was founded in Kuching, Malaysia
            in 1994. In 2006, the contest was held outside of Malaysia for the
            first time in China. In 2016, 60 countries participated in the
            Pageant. The Motto is embracing beauty, welcoming cultural
            diversity. SWA last participated in this Pageant was 2017.
          </p>
        </div>
        <div className="flex items-center justify-center px-3 space-x-3 lg:space-x-4">
          {/* <div className="grid flex-shrink-0 grid-cols-1 gap-y-3 lg:gap-y-4">
              <div className="h-64 overflow-hidden rounded-lg w-36 sm:w-44 sm:opacity-0 lg:opacity-100">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1634181801/mspiPage/musbp0wsm7xm7vludfts.jpg"
                  alt=""
                  className="object-cover object-top w-full h-full"
                />
              </div>
              <div className="w-32 h-64 overflow-hidden rounded-lg sm:w-44">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1634181800/mspiPage/q4bfpj6gyuedjjfvfg0y.jpg"
                  alt=""
                  className="object-cover object-top w-full h-full"
                />
              </div>
            </div> */}
        </div>
      </div>
    </section>
  );
};

export default MissTourism;
