import React from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function Example({ open, setOpen }) {
  //   const [open, setOpen] = useState(true);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg md:max-w-2xl lg:max-w-4xl sm:w-full sm:p-6">
              <div>
                {/* <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                  <CheckIcon
                    className="w-6 h-6 text-green-600"
                    aria-hidden="true"
                  />
                </div> */}
                <div className="mt-3 text-lg text-left lg:text-xl sm:mt-5 text-swa-2">
                  {/* <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Payment successful
                  </Dialog.Title> */}

                  <p>
                    The history of the Miss International Beauty Pageant spans
                    around half a century of the passion and energy of delegates
                    from around the world who have sought to "contribute to
                    global society."
                  </p>
                  <br />
                  <p>
                    Miss International began in 1960 in Long Beach, California,
                    where it was staged until 1967, before moving to Japan from
                    1968-1970 to commemorate the Osaka World Expo (the 1968
                    Pageant was held at the Nippon Budokan, with the "Ginza
                    Parade" from Hibiya Park to Nihonbashi received to great
                    acclaim). This led to it being staged in Japan every year
                    from 1972 onward. What is more, Miss International
                    contestants have appeared at every international expo held
                    in Japan so far, lending glamour to the venue.
                  </p>
                  <br />
                  <p>
                    Under the slogans of "achieving world peace through mutual
                    understanding" and "proper understanding of Japan in a
                    globalized society," the Miss International Beauty Pageant
                    has grown into a quasi-national event so that it is now
                    known as the "Olympics of Beauty." The aim of this "Beauty
                    Festival" is for each delegate to compete not on external
                    beauty alone, but as popular-level diplomats who proactively
                    carry out international exchange activities such as exchange
                    with the regional community and the Miss International
                    Forum, as well as social contribution activities such as
                    charity events and ecology campaigns.
                  </p>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-swa-3 hover:bg-swa-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-swa-4 sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
