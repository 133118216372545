import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { FaFacebook } from "react-icons/fa";
import MSPIModal from "./MissInternationalModal";

const MissInternational = () => {
  const [open, setOpen] = useState(false);

  const clickHandler = () => {
    setOpen(!open);
  };

  return (
    <section className="mb-5">
      <MSPIModal open={open} setOpen={setOpen} />
      <div className="">
        <div className="flex flex-col justify-start max-w-2xl px-4 py-24 mx-auto space-y-12 xl:space-y-0 xl:space-x-5 xl:flex-row sm:px-6 lg:max-w-7xl lg:px-8 ">
          {/* className="max-w-2xl mx-auto py-24 px-4 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8 */}
          <div className="self-center md:max-w-xl">
            <h2 className="text-4xl font-bold tracking-tight text-swa-1 font sm:text-6xl">
              Miss Singapore International
            </h2>
            <p className="mt-4 text-xl text-gray-500">
              Miss Singapore International will represent Singapore on an
              International platform with 84 contestants around the world. The
              exchanges and exposure aim to bring forth more women to anchor a
              role in the society with a charitable approach, grace, integrity
              and honour. We hope that future generations of women will play a
              part in holding the flag high by extending themselves in their
              lifetime.
            </p>
            <div
              onClick={clickHandler}
              className="inline-block mt-3 btn-dark"
              // className="inline-block px-8 py-3 mt-3 font-medium text-center text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-2"
            >
              Read more
            </div>
            <a
              className="flex mt-5 cursor-pointer item-start"
              href="https://www.facebook.com/MissSingaporePageantInternational"
              target="_blank"
              rel="noreferrer"
            >
              <div className="inline-block p-2 text-3xl text-opacity-75 text-swa-3 animate-pulse">
                <FaFacebook />
              </div>
              <div className="inline-block max-w-lg ">
                Stay updated on latest activities on MIss Singapore
                International by following us on Facebook
              </div>
            </a>
          </div>

          <div className="flex items-center justify-center px-3 space-x-1 lg:space-x-4">
            <div className="grid flex-shrink-0 grid-cols-1 gap-y-3 lg:gap-y-4">
              <div className="h-64 overflow-hidden rounded-lg w-36 sm:w-44 sm:opacity-0 lg:opacity-100">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1665374118/mspiPage/vzgkaohgyr7rqof7ruci.webp"
                  alt="Scarlett Sim"
                  className="object-cover object-top w-full h-full"
                  objectPosition={"top"}
                />
              </div>
              <div className="w-32 h-64 overflow-hidden rounded-lg sm:w-44">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1634179261/mspiPage/dorholk0l06hfbzphkuj.jpg"
                  alt="Eileen Fong"
                  className="object-cover object-top w-full h-full"
                  objectPosition={"top"}
                />
              </div>
            </div>
            <div className="grid flex-shrink-0 grid-cols-1 gap-y-3 lg:gap-y-4">
              <div className="w-32 h-64 overflow-hidden rounded-lg sm:w-44">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1634179260/mspiPage/crser9dspwfxdhuvxo1l.jpg"
                  alt="Kylie Yeo"
                  className="object-cover object-top w-full h-full"
                  objectPosition={"top"}
                />
              </div>
              <div className="w-32 h-64 overflow-hidden rounded-lg sm:w-44">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1634182600/mspiPage/mafukpihpxjyjwp20tel.jpg"
                  alt="Charlotte Lucille Chia"
                  className="object-cover object-top w-full h-full"
                  objectPosition={"top"}
                />
              </div>
              <div className="w-32 h-64 overflow-hidden rounded-lg sm:w-44">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1634179260/mspiPage/tgrvvjn6hckhnlqefblr.jpg"
                  alt="Wang Huiqi"
                  className="object-cover object-center w-full h-full"
                  objectPosition={"top"}
                />
              </div>
            </div>
            <div className="grid flex-shrink-0 grid-cols-1 gap-y-3 lg:gap-y-4">
              <div className="w-32 h-64 overflow-hidden rounded-lg sm:w-44">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636453502/mspiPage/zqf6wwiofma1fudsmexs.png"
                  alt=""
                  className="object-cover object-top w-full h-full"
                  objectPosition={"top"}
                />
              </div>
              <div className="w-32 h-64 overflow-hidden rounded-lg sm:w-44">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636453466/mspiPage/ntazam7lergoood23d4p.jpg"
                  alt="Roxanne Zhang"
                  className="object-cover object-top w-full h-full"
                  objectPosition={"top"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissInternational;
