import React from "react";
import { FaFacebook } from "react-icons/fa";
import { StaticImage } from "gatsby-plugin-image";

const MissAsiaPacific = () => {
  return (
    <section className="mb-5">
      <div className="">
        <div className="flex flex-col justify-center max-w-2xl px-4 py-24 mx-auto space-y-12 xl:space-y-0 xl:space-x-5 xl:flex-row sm:px-6 lg:max-w-7xl lg:px-8 ">
          <div className="self-center md:max-w-xl">
            <h2 className="text-4xl font-bold tracking-tight text-swa-1 font sm:text-6xl">
              Miss Singapore Asia Pacific Singapore International
            </h2>
            <p className="mt-4 text-xl text-gray-500">
              Miss Singapore Asia Pacific International is an organisation that
              enables women to embrace, express, and exercise their
              individuality. Miss Asia Pacific International is a beauty pageant
              headquartered in Manila, Philippines and held in various locations
              in Asia and Oceania since 1968. It is the oldest such pageant in
              Asia
            </p>

            <a
              className="flex mt-5 mb-10 cursor-pointer lg:mb-0 item-start"
              href="https://www.facebook.com/Miss-Singapore-Asia-Pacific-International-668565483321509"
              target="_blank"
              rel="noreferrer"
            >
              <div className="inline-block p-2 text-3xl text-opacity-75 text-swa-3 animate-pulse">
                <FaFacebook />
              </div>
              <div className="inline-block max-w-lg ">
                Stay updated on latest activities on MIss Singapore
                International by following us on Facebook
              </div>
            </a>
          </div>
          <div className="flex items-center justify-center px-3 space-x-3 lg:space-x-4">
            <div className="grid flex-shrink-0 grid-cols-1 gap-y-3 lg:gap-y-4">
              <div className="h-64 overflow-hidden rounded-lg w-36 sm:w-44 ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1634181506/mspiPage/k5olehjrzuscoonupoyl.jpg"
                  alt=""
                  className="object-cover object-top w-full h-full"
                  objectPosition={"top"}
                />
              </div>
              <div className="w-32 h-64 overflow-hidden rounded-lg sm:w-44">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1634181505/mspiPage/syfrp2snvixwt4ndrbe4.jpg"
                  alt=""
                  className="object-cover object-top w-full h-full"
                  objectPosition={"top"}
                />
              </div>
            </div>
            <div className="grid flex-shrink-0 grid-cols-1 gap-y-3 lg:gap-y-4">
              <div className="w-32 h-64 overflow-hidden rounded-lg sm:w-44">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1634181506/mspiPage/f4pawxfd1peaigksaxgo.jpg"
                  alt=""
                  className="object-cover object-top w-full h-full"
                  objectPosition={"top"}
                />
              </div>
              <div className="w-32 h-64 overflow-hidden rounded-lg sm:w-44">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1634181505/mspiPage/npfaz3tms3erxxo3g2bm.jpg"
                  alt=""
                  className="object-cover object-top w-full h-full"
                  objectPosition={"top"}
                />
              </div>
              <div className="w-32 h-32 overflow-hidden rounded-lg sm:w-44">
                {/* <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1634179260/mspiPage/tgrvvjn6hckhnlqefblr.jpg"
                  alt="Wang Huiqi"
                  className="object-cover object-center w-full h-full"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissAsiaPacific;
