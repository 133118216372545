import React from "react";
import { StaticImage } from "gatsby-plugin-image";
const people = [
  {
    name: "Lindsay Walton",
    role: "Front-end Developer",
    imageUrl:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    twitterUrl: "#",
    linkedinUrl: "#",
  },
  // More people...
];

const Intro = () => {
  return (
    <section className="mt-10 text-xl font-light border-black text-swa-2">
      <div className="max-w-2xl px-4 py-24 mx-auto sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-4xl font-extrabold tracking-tight text-swa-1 sm:text-7xl">
            Miss Singapore Pageant International
          </h1>
        </div>

        <div className="mt-16 space-y-16">
          <div className="bg-gray-50">
            <div className="py-6 mx-auto max-w-7xl sm:px-2 sm:py-12 lg:px-4">
              <div className="max-w-2xl px-4 mx-auto lg:max-w-none">
                <div className="grid items-center grid-cols-1 gap-y-10 gap-x-16 lg:grid-cols-2">
                  <div>
                    <h2 className="text-4xl font-bold tracking-tight text-swa-1 sm:text-5xl lg:text-6xl">
                      About Miss International
                    </h2>
                    <p className="mt-4 text-gray-600">
                      The unique proposition of Miss International is the
                      promotion of key virtues - charity, goodwill and grace.
                      Personality plays a larger role than looks for this
                      particular pageant where integrity is integral.
                    </p>
                    <p className="mt-4 text-gray-600">
                      Miss International established a “Miss International
                      Foundation” which aids underprivileged children through
                      various beneficiaries like UNICEF and other charity
                      institutions.
                    </p>
                  </div>
                  <div className="overflow-hidden bg-gray-100 rounded-lg aspect-w-3 aspect-h-2">
                    <img
                      src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1665640397/mspiPage/d9xyq3knpsciybaqp9z9.webp"
                      alt=""
                      className="object-cover object-center"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50">
            <div className="py-6 mx-auto max-w-7xl sm:px-2 sm:py-12 lg:px-4">
              <div className="max-w-2xl px-4 mx-auto lg:max-w-none">
                <div className="grid items-center grid-cols-1 gap-y-10 gap-x-16 lg:grid-cols-2">
                  <div className="overflow-hidden bg-gray-100 rounded-lg aspect-w-3 aspect-h-2">
                    <img
                      src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1665547378/charityDinnerPage/mc2opdgvz52guttckbgn.webp"
                      alt=""
                      className="object-cover object-center"
                    />
                  </div>
                  <div>
                    {/* <h2 className="text-4xl font-bold tracking-tight text-gray-900">
                      About Miss International
                    </h2> */}
                    <p className="mt-4 text-gray-600">
                      Young women are groomed to be female ambassadors and learn
                      from experience to be pillars of society by upholding the
                      time-tested honour of sharing. Various Miss Internationals
                      have undertaken roles to be mentors in their own fields
                      and embarked on personal journeys to be involved in
                      charity and pay it forward with their skills and talents.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
