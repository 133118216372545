import React, { useState } from "react";
import { FaFacebook } from "react-icons/fa";
import SupraModal from "./MissSupranationalModal";
import { StaticImage } from "gatsby-plugin-image";

const MissSupranational = () => {
  const [open, setOpen] = useState(false);

  const clickHandler = () => {
    setOpen(!open);
  };

  return (
    <section className="mb-5">
      <SupraModal open={open} setOpen={setOpen} />
      <div className="">
        <div className="flex flex-col-reverse justify-center max-w-2xl px-4 py-24 mx-auto space-y-12 xl:space-y-0 xl:space-x-5 xl:flex-row sm:px-6 lg:max-w-7xl lg:px-8 ">
          <div className="flex items-center justify-center px-3 space-x-3 lg:space-x-4">
            <div className="grid flex-shrink-0 grid-cols-1 mt-10 gap-y-3 lg:gap-y-4">
              <div className="h-64 overflow-hidden rounded-lg w-36 sm:w-44 ">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1665374516/mspiPage/nxwgj7ronxhaxj9xpamf.webp"
                  alt="Jiayi Sin"
                  className="object-cover object-top w-full h-full"
                  objectPosition={"top"}
                />
              </div>
              <div className="w-32 h-64 overflow-hidden rounded-lg sm:w-44">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1634180555/mspiPage/miptud9mcgn4mfq345jl.jpg"
                  alt="Priyanka Annuncia"
                  className="object-cover object-top w-full h-full"
                  objectPosition={"top"}
                />
              </div>
            </div>
            <div className="grid flex-shrink-0 grid-cols-1 gap-y-3 lg:gap-y-4">
              <div className="w-32 h-64 overflow-hidden rounded-lg sm:w-44">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1634180554/mspiPage/iv4zjhgvdq54lorfnh6m.jpg"
                  alt="Naomi Huth"
                  className="object-cover object-top w-full h-full"
                  objectPosition={"top"}
                />
              </div>
              <div className="w-32 h-64 overflow-hidden rounded-lg sm:w-44">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1634180554/mspiPage/egfoqbllljcjdbquqgpq.jpg"
                  alt="Lynn Teo"
                  className="object-cover object-top w-full h-full"
                  objectPosition={"top"}
                />
              </div>
              {/* <div className="w-32 h-64 overflow-hidden rounded-lg sm:w-44">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1634179260/mspiPage/tgrvvjn6hckhnlqefblr.jpg"
                  alt="Wang Huiqi"
                  className="object-cover object-center w-full h-full"
                />
              </div> */}
            </div>
            {/* <div className="grid flex-shrink-0 grid-cols-1 gap-y-3 lg:gap-y-4">
              <div className="w-32 h-64 overflow-hidden rounded-lg sm:w-44">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636453502/mspiPage/zqf6wwiofma1fudsmexs.png"
                  alt=""
                  className="object-cover object-top w-full h-full"
                />
              </div>
              <div className="w-32 h-64 overflow-hidden rounded-lg sm:w-44">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636453466/mspiPage/ntazam7lergoood23d4p.jpg"
                  alt="Roxanne Zhang"
                  className="object-cover object-top w-full h-full"
                />
              </div>
            </div> */}
          </div>
          <div className="self-center md:max-w-xl">
            <h2 className="text-4xl font-bold tracking-tight text-swa-1 font sm:text-6xl">
              Miss Singapore Supranational
            </h2>
            <p className="mt-4 text-xl text-gray-500">
              Miss Supranational is a unique title that has started in Poland in
              2009. SWA has been sending Singapore representatives to Miss
              Supranational since 2015. Annually there are 74 contestants
              internationally in Poland. The winner drives awareness with
              appearances for causes with animal conservatories/ children with
              HIV and other meaningful work to highlight world issues. A lot of
              spotlights has been given to Miss Supranational despite its short
              history as it has made its way to present the Queens in events in
              Cannes and many TV media productions.
              <br />
              <br />
              It is an honour for SWA to hold the rights to Miss Supranational
              as it is aligned with our Charity element and it has given plenty
              of exposure to our Singapore representatives annually.
            </p>
            <div
              onClick={clickHandler}
              className="inline-block mt-3 btn-dark"
              // className="inline-block px-8 py-3 mt-3 font-medium text-center text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-2"
            >
              Read more
            </div>
            <a
              className="flex mt-5 mb-10 cursor-pointer lg:mb-0 item-start"
              href="https://www.facebook.com/MissSingaporeSupranational"
              target="_blank"
              rel="noreferrer"
            >
              <div className="inline-block p-2 text-3xl text-opacity-75 text-swa-3 animate-pulse">
                <FaFacebook />
              </div>
              <div className="inline-block max-w-lg ">
                Stay updated on latest activities on MIss Singapore
                International by following us on Facebook
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissSupranational;
