import React from "react";

// const people = [
//   {
//     name: "Scarlett Sim",
//     role: "Miss Singapore International 2022",
//     imageUrl:
//       "https://res.cloudinary.com/dkm14ge3g/image/upload/v1634182600/mspiPage/mafukpihpxjyjwp20tel.jpg",
//     bio: "My name is Scarlett Sim, and I am a flight attendant who creates an exquisite experience for passengers by providing safety and personalized services. I build, connect, and maintain positive relationships, always making the first impression count and leaving the last impression that stays. Outside of work, I enjoy reading books and going to the gym. I believe that volunteering connects people from all walks of life; a great way to meet new people with common interests. Helping others kindles happiness, and seeing the smiles on the elderly face warms my heart. It is a rewarding feeling when one gives back to the community, which helps us achieve greater life satisfaction",
//     twitterUrl: "#",
//     linkedinUrl: "#",
//   },
//   {
//     name: "Jiayi Sin",
//     role: "Miss Singapore Supranational 2022",
//     imageUrl:
//       "https://res.cloudinary.com/dkm14ge3g/image/upload/v1634182600/mspiPage/krybyvi0zjjikparayqm.jpg",
//     bio: `<p>My name is Jiayi Sin, and I am a fresh graduate with a Bachelor’s Degree in Marketing. I am also currently a model and I enjoy all things sports, especially badminton!.
//     Growing up, my parents are strong believers of independence. They encouraged me to start working part time at a young age of 14 years old to earn money for the things that I want. Going through this phase has taught me to be a self-motivated and responsible person.</p>

//     <p>I have also been taught the value of empathy, and it somehow grew in me to be a person who became passionate about serving the people around me. As such, I strongly believe that it is important to give back to the community. I am also blessed to be able to spend my growing up years with my dear grandmother, and this special experience has helped me better understand the intricacies of developing relationships with elderlies. Knowing that my presence provided much joy and comfort to the elderlies around me brought smiles to my face, and reinforced my passion to give back to the community.</p>`,
//     twitterUrl: "#",
//     linkedinUrl: "#",
//   },
// ];

export default function CurrentQueen() {
  return (
    <div className="bg-gray-50">
      <div className="px-4 py-16 mx-auto max-w-7xl sm:py-24 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-baseline sm:justify-between">
          {/* <h1 className="text-4xl font-bold tracking-tight text-swa-1 sm:text-7xl">
            Miss Singapore Pageant International
          </h1> */}
        </div>
        <div className="relative">
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="relative mt-5 shadow-xl sm:overflow-hidden sm:rounded-2xl">
                <div className="absolute inset-0 md:hidden">
                  <img
                    className="object-cover w-full h-full "
                    // src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1693893290/hero/btxjrm17urrzcgrgzzao.webp"
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1694519343/mspiPage/l5f9t4dxk0gn9b6abhc5.webp"
                    alt="SWA Hero Image"
                  />
                  <div className="absolute inset-0 bg-gray-300 mix-blend-multiply" />
                </div>
                <div className="absolute inset-0">
                  <img
                    className="hidden object-cover w-full h-full md:block"
                    // src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1693893290/hero/btxjrm17urrzcgrgzzao.webp"
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1694517732/hero/ihm9sfhiykajgx1y8yzw.webp"
                    alt="SWA Hero Image"
                  />
                  {/* <div className="absolute inset-0 bg-swa-2 mix-blend-multiply" /> */}
                </div>
                <div className="relative py-48 mt-3 px-34 sm:px-6 sm:py-24 lg:py-48 lg:mt-16 sm:mt-8 lg:px-8">
        

    

                </div>
              </div>
            </div>
          </div>
        <div className="bg-gray-50">
          <div className="py-6 mx-auto max-w-7xl sm:px-2 sm:py-12 lg:px-4">
            <div className="max-w-2xl px-4 mx-auto lg:max-w-none">
              <div className="grid items-center grid-cols-1 gap-y-10 gap-x-3 lg:grid-cols-2">
                <div>
                  <h2 className="text-2xl font-bold tracking-tight text-swa-1 sm:text-3xl lg:text-4xl">
                    Chavelle Chong
                  </h2>
                  <p className="text-xl text-swa-1">
                    Miss Singapore International 2023
                  </p>
                  <p className="mt-4 text-xl text-gray-600">
                  My name is Chavelle Chong, a Year 2 student pursuing a degree in real estate at the National University of Singapore. I am passionate about sustainable urban planning to shape  a greener future for generations as I believe that the choices we make today can have a profound impact on the world we leave behind. I am also interested in entrepreneurship and adore the thrill of roller coasters and the spontaneity of adventures. 
                  </p>
                  <p className="mt-4 text-xl text-gray-600">
                  Apart from my academic interests, I am a mental wellness advocate. Through the Singapore Women's Association's laughter yoga sessions, I've seen how positivity and mindfulness can boost mental health. To further this cause, I have also developed a card game aimed at raising awareness about mental health, hoping to contribute to a more open and supportive dialogue surrounding mental wellness.
                  </p>
                </div>
                <div className="overflow-hidden rounded-lg aspect-w-3 aspect-h-10 sm:aspect-h-3">
                  <div className="grid grid-cols-1 mt-6 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8">
                    <div className="overflow-hidden rounded-lg group aspect-w-1 aspect-h-1 sm:aspect-h-2 sm:aspect-w-1 sm:row-span-2">
                      <img
                        src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1694475489/mspiPage/qqd4esmtaxcgl6whfehm.webp"
                        // src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1693105860/mspiPage/qdus6pmwlczzrvjucp6d.webp"
                        alt="Miss Singapore International 2023, Chavelle Chong"
                        className="object-cover object-top group-hover:opacity-75"
                      />
                    </div>
                    <div className="overflow-hidden rounded-lg group aspect-w-1 aspect-h-1 sm:aspect-none sm:relative sm:h-full">
                      <img
                        src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1693105859/mspiPage/azewc2hjknbg7ngdbtpr.webp"
                        alt="Miss Singapore International 2023, Chavelle Chong"
                        className="object-cover object-top group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full"
                      />
                    </div>
                    <div className="overflow-hidden rounded-lg group aspect-w-1 aspect-h-1 sm:aspect-none sm:relative sm:h-full">
                      <img
                        src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1693105859/mspiPage/cs4mnb4tycct63xtv3ka.webp"
                        // src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1693105859/mspiPage/cs4mnb4tycct63xtv3ka.webp"
                        alt="Miss Singapore International 2023, Chavelle Chong"
                        className="object-cover object-top group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative md:hidden">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 text-sm text-gray-500 bg-white">
              * * * * *
            </span>
          </div>
        </div>
        <div className="bg-gray-50">
          <div className="py-6 mx-auto max-w-7xl sm:px-2 sm:py-12 lg:px-4">
            <div className="max-w-2xl px-4 mx-auto lg:max-w-none">
              {/* <div className="grid items-center grid-cols-1 gap-y-10 gap-x-16 lg:grid-cols-2">
                <div>
                  <h2 className="text-2xl font-bold tracking-tight text-swa-1 sm:text-3xl lg:text-4xl">
                    Jiayi Sin
                  </h2>
                  <p className="text-xl text-swa-1">
                    Miss Singapore Supranational 2022
                  </p>
                  <p className="mt-4 text-xl text-gray-600">
                    My name is Jiayi Sin, and I am a fresh graduate with a
                    Bachelor’s Degree in Marketing. I am also currently a model
                    and I enjoy all things sports, especially badminton!
                  </p>
                  <p className="mt-4 text-xl text-gray-600">
                    Growing up, my parents are strong believers of independence.
                    They encouraged me to start working part time at a young age
                    of 14 years old to earn money for the things that I want.
                    Going through this phase has taught me to be a
                    self-motivated and responsible person.
                  </p>
                  <p className="mt-4 text-xl text-gray-600">
                    I have also been taught the value of empathy, and it somehow
                    grew in me to be a person who became passionate about
                    serving the people around me. As such, I strongly believe
                    that it is important to give back to the community. I am
                    also blessed to be able to spend my growing up years with my
                    dear grandmother, and this special experience has helped me
                    better understand the intricacies of developing
                    relationships with elderlies. Knowing that my presence
                    provided much joy and comfort to the elderlies around me
                    brought smiles to my face, and reinforced my passion to give
                    back to the community.
                  </p>
                </div>
                <div className="overflow-hidden rounded-lg aspect-w-3 aspect-h-10 sm:aspect-h-3">
                  <div className="grid grid-cols-1 mt-6 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8">
                    <div className="overflow-hidden rounded-lg group aspect-w-1 aspect-h-1 sm:aspect-h-2 sm:aspect-w-1 sm:row-span-2">
                      <img
                        src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1665373741/mspiPage/vwm2xjhfa3bqab7pxcif.webp"
                        alt="Miss Singapore Supranational 2022, Jiayi Sin"
                        className="object-cover object-top group-hover:opacity-75"
                      />
                    </div>
                    <div className="overflow-hidden rounded-lg group aspect-w-1 aspect-h-1 sm:aspect-none sm:relative sm:h-full">
                      <img
                        src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1665643603/mspiPage/zcxnjb3anhqibdfem009.webp"
                        alt="Miss Singapore Supranational 2022, Jiayi Sin"
                        className="object-cover object-top group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full"
                      />
                    </div>
                    <div className="overflow-hidden rounded-lg group aspect-w-1 aspect-h-1 sm:aspect-none sm:relative sm:h-full">
                      <img
                        src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1665643884/mspiPage/hhqmem1dnjhcfrbtcpl2.webp"
                        alt="Miss Singapore Supranational 2022, Jiayi Sin"
                        className="object-cover object-top group-hover:opacity-75 sm:absolute sm:inset-0 sm:h-full sm:w-full"
                      />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
