import React from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function SupraModal({ open, setOpen }) {
  //   const [open, setOpen] = useState(true);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg md:max-w-2xl lg:max-w-4xl sm:w-full sm:p-6">
              <div>
                {/* <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                  <CheckIcon
                    className="w-6 h-6 text-green-600"
                    aria-hidden="true"
                  />
                </div> */}
                <div className="mt-3 text-lg text-left lg:text-xl sm:mt-5 text-swa-2">
                  {/* <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Payment successful
                  </Dialog.Title> */}
                  <p>
                    The first Miss Supranational competition began life in 2009
                    held in the beautiful city of Płock, Poland.  The first-ever
                    group of contestants came from almost 40 countries around
                    the world. Since then the number has more than doubled to
                    more than 80 competitors from every continent.
                  </p>
                  ​
                  <p>
                    This proved that Miss Supranational has developed into one
                    of the world’s most prestigious live TV Shows. The 2010 and
                    2011 editions were also broadcasted live by satellite from
                    Plock but in 2012 the competition changed locations and
                    moved the event to the capital city of Poland, Warsaw. In
                    2013, with interest growing internationally, the pageant
                    travelled for the first time to another country. The capital
                    city of Belarus, Minsk, played host to a spectacular show
                    which was presented the Global Beauties Award for BEST STAGE
                    of the year beating the more traditional and established
                    pageants. 2014 contest saw the return of the competition to
                    its roots in Poland and this time the honour went to the
                    city of Krynica –Zdrój. The show’s television audience has
                    grown remarkably quickly since the beginning and now rivals
                    other pageants as having worldwide audiences of more than
                    100 Million people in more than 120 countries.
                  </p>
                  <br />
                  <p>
                    The 8th edition of Miss Supranational 2017 returned to the
                    winter wonderland of Krynica–Zdrój for a third time to enjoy
                    the warm-hearted nature of the people and the extraordinary
                    setting of the contest.
                  </p>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-swa-3 hover:bg-swa-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-swa-4 sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
