import React from "react";

const AnimatedBackground = () => {
  return (
    <div className="absolute w-full">
      <div className="relative mx-auto h-96">
        {/* <div className="absolute w-56 h-56 rounded-full bg-opacity-40 top-32 left-24 bg-swa-2 mix-blend-multiply filter blur-xl "></div> */}
        <div className="absolute w-48 h-48 bg-purple-300 bg-opacity-50 rounded-full top-16 right-1/3 md:left-1/3 mix-blend-multiply animate-pulse filter blur"></div>
        <div className="absolute w-56 h-56 bg-pink-300 bg-opacity-50 rounded-full top-32 right-1/4 md:left-1/4 mix-blend-multiply animate-pulse filter blur"></div>
        <div className="absolute top-0 rounded-full bg-opacity-70 right-1/2 md:left-1/2 w-72 h-72 bg-swa-4 mix-blend-multiply animate-pulse filter blur"></div>
      </div>
    </div>
  );
};

export default AnimatedBackground;
